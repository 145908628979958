import qs from "qs";
import {api} from "./api";
import {FilterSpecDto} from "./sharedApiModels";
import {QueryMeta} from "react-query";
import {from, IEnumerable} from "linq-to-typescript";

export type DataValue = number | string | boolean | null

export type QueryRequest = {
    dataset: string
    select: QuerySelectColumnDto[]
    filters?: FilterSpecDto
    orderBy?: OrderByDto[]
    requestCountOnly?: boolean
    limit?: number
    offset?: number
    queryMeta?: QueryMeta
}

export type QuerySelectColumnDto = {
    name: string
    alias?: string
    args?: ArgValueDto[]
}

export type ArgValueDto = {
    name: string
    value: DataValue
}

export type OrderByDto = {
    name: string
    desc?: boolean
}

export type DataSetResultDto = {
    dataSetName: string
    columns: ColumnDto[]
    data: DataValue[][]
}

export class DataSetAccessor {
    private readonly colMap: {[colName: string]: ColumnDto}

    public constructor(private ds: DataSetResultDto) {
        this.colMap = {}
        ds.columns.forEach(x => this.colMap[x.name] = x)
    }

    public getColumn(colName: string): ColumnDto {
        return this.colMap[colName]
    }

    public getValue<T extends DataValue>(rowIndex: number, colName: string): T {
        return this.ds.data[rowIndex][this.colMap[colName].index] as T
    }

    public getRow(rowIndex: number) {
        return toObject(from(this.ds.columns), c => c.name, c => this.ds.data[rowIndex][c.index])
    }

    public getRows() {
        return from(this.ds.data).select((x, i) => this.getRow(i))
    }
}

function toObject<TSource, TKey extends keyof any, TVal>(src: IEnumerable<TSource>, keySelector: (x: TSource) => TKey, valSelector: (x: TSource) => TVal): Record<TKey, TVal> {
    return src.aggregate({} as Record<TKey, TVal>, (acc, x) => {
        acc[keySelector(x)] = valSelector(x)
        return acc
    })
}

export type ColumnDto = {
    name: string
    index: number
    displayName?: string
    type: DataValueType
    isMeasure: boolean
    originalName: string
}

export enum DataValueType {
    String = 'String',
    Bool = 'Bool',
    Integer = 'Integer',
    Double = 'Double',
    Period = 'Period',
    Instant = 'Instant',
    TruncateField = 'TruncateField',
}

export type FilterValuesResponse = {
    teams: FilterValueDto[]
    projects: FilterValueDto[]
    epics: FilterValueDto[]
    issueTypes: IssueTypeFilterValueDto[]
}

export type FilterValueDto = {
    value: string
    name: string
}

export type IssueTypeFilterValueDto = FilterValueDto & {
    isUserStory: boolean
    isDefect:  boolean
}

export class DataSetApi {
    public async getFilterValues(estimatedOnly = false, signal?: AbortSignal) {
        return await api.get<FilterValuesResponse>(`dataSet/filterValues`, new URLSearchParams({
            estimatedOnly: estimatedOnly.toString()
        }), signal)
    }

    public async query(request: QueryRequest, signal?: AbortSignal) {
        const query = request && qs.stringify(request, { allowDots: true })
        return await api.get<DataSetResultDto>(`dataSet/query`, query, signal)
    }
}

export const dataSetApi = new DataSetApi()