import {createContext, ReactNode, useContext, useState} from "react";
import {FilterValueDto, IssueTypeFilterValueDto} from "@services/dataSetApi";
import {FilterSpecDto} from "@services/sharedApiModels";
import {from} from "linq-to-typescript";
import {DateRangePickerValue} from "@tremor/react";
import {toLocalISODateTimeString} from "@components/common/dateTimeStringUtils";

type Props = {
    children?: ReactNode
}

export enum FilterId {
    TimeRange = 'timeRange',
    Projects = 'projects',
    Teams = 'teams',
    IssueTypes = 'issueTypes',
    DefectTypes = 'defectTypes',
    Sprint = 'sprint',
    SprintGroup = 'sprintGroup',
}

enum FilterKind {
    TimeRange = 'TimeRange',
    MultiSelect = 'MultiSelect',
    Select = 'Select',
}

type FilterDef = {
    id: FilterId
    name: string
    kind: FilterKind,
    applyFilter: (spec: FilterSpecDto, ctx: IFilterContextValues) => FilterSpecDto
}

export const filterDefs: FilterDef[] = [
    {
        id: FilterId.TimeRange,
        name: 'Временной период',
        kind: FilterKind.TimeRange,
        applyFilter: (spec, filters) => {
            const [from, to] = getTimeRange(filters.timeRange)

            spec.timeRange = {
                start: format(from),
                end: format(to),
            }

            return spec

            function format(date?: Date): string | undefined {
                if (date !== undefined)
                    return toLocalISODateTimeString(date)
            }
        }
    },
    {
        id: FilterId.Teams,
        name: 'Команды',
        kind: FilterKind.MultiSelect,
        applyFilter: (spec, filters) => {
            spec.teamIds = filters.teams.map(x => x.value)
            return spec
        }
    },
    {
        id: FilterId.Projects,
        name: 'Проекты',
        kind: FilterKind.MultiSelect,
        applyFilter: (spec, filters) => {
            spec.projectIds = filters.projects.map(x => x.value)
            return spec
        }
    },
    {
        id: FilterId.IssueTypes,
        name: 'Типы задач',
        kind: FilterKind.MultiSelect,
        applyFilter: (spec, filters) => {
            spec.issueTypeIds = filters.issueTypes.map(x => x.value)
            return spec
        }
    },
    {
        id: FilterId.DefectTypes,
        name: 'Типы дефектов',
        kind: FilterKind.MultiSelect,
        applyFilter: (spec, filters) => {
            spec.issueTypeIds = filters.defectTypes.map(x => x.value)
            spec.defectsOnly = true
            return spec
        }
    },
    {
        id: FilterId.Sprint,
        name: 'Спринт',
        kind: FilterKind.Select,
        applyFilter: (spec, filters) => {
            spec.sprintId = filters.sprintId
            return spec
        }
    },
    {
        id: FilterId.SprintGroup,
        name: 'Проект/команда',
        kind: FilterKind.Select,
        applyFilter: (spec, filters) => {
            spec.sprintGroupId = filters.sprintGroupId
            return spec
        }
    },
]

const filterDefsById = from(filterDefs).toObject(x => x.id)

export type TimeRangeValueType =
    'lastYear' |
    'lastQuarter' |
    'lastMonth' |
    'lastWeek' |
    'allTime' |
    'custom'

export type TimeRangeValueTypeDef = {
    type: TimeRangeValueType
    name: string
}

export class TimeRangeValueTypes {
    static readonly All: TimeRangeValueTypeDef[] = [
        {type: 'allTime', name: 'Все время'},
        {type: 'lastYear', name: 'Последний год'},
        {type: 'lastQuarter', name: 'Последняя четверть'},
        {type: 'lastMonth', name: 'Последний месяц'},
        {type: 'lastWeek', name: 'Последния неделя'},
        {type: 'custom', name: 'Указанный период'},
    ]
}

export type TimeRangeValue = {
    type: TimeRangeValueType
    custom?: DateRangePickerValue
}

export function getTimeRange(rangeVal: TimeRangeValue): [Date?, Date?] {
    const from = new Date(new Date().toDateString())
    switch (rangeVal.type) {
        case 'allTime':
            return [undefined, undefined]
        case 'custom':
            return [rangeVal.custom?.from, rangeVal.custom?.to]
        case 'lastYear':
            from.setFullYear(from.getFullYear() - 1)
            break;
        case 'lastQuarter':
            from.setMonth(from.getMonth() - 3)
            break;
        case 'lastMonth':
            from.setMonth(from.getMonth() - 1)
            break;
        case 'lastWeek':
            from.setDate(from.getDate() - 7)
            break;
    }

    return [from, new Date(new Date().toDateString())]
}

type IFilterContextValues = {
    isLoaded: boolean
    timeRange: TimeRangeValue
    teams: FilterValueDto[]
    projects: FilterValueDto[]
    issueTypes: IssueTypeFilterValueDto[]
    defectTypes: IssueTypeFilterValueDto[]
    sprintId?: string
    sprintGroupId: string | null
    stageNamePattern: string
    jobNamePattern: string
}

export type IFiltersContext = IFilterContextValues & {
    getFilterSpec: (filters: FilterId[]) => FilterSpecDto
}

export type IScopeFiltersContext = {
    scopeFilters: FilterId[]
    setScopeFilters: (filters: FilterId[]) => void
}

type IEditableFilterContext = IFiltersContext & {
    setIsLoaded: (val: boolean) => void
    setTimeRange: (timeRange: TimeRangeValue) => void
    setTeams: (teams: FilterValueDto[]) => void
    setProjects: (projects: FilterValueDto[]) => void
    setIssueTypes: (issueTypes: IssueTypeFilterValueDto[]) => void
    setDefectTypes: (issueTypes: IssueTypeFilterValueDto[]) => void
    setSprintId: (sprintId?: string) => void
    setSprintGroupId: (sprintGroupId: string | null) => void
    setStageNamePattern: (stageNamePattern: string) => void
    setJobNamePattern: (jobNamePattern: string) => void
}

const initialContext: IEditableFilterContext & IScopeFiltersContext = {
    isLoaded: false,
    timeRange: {type: 'lastYear'},
    scopeFilters: [],
    teams: [],
    projects: [],
    issueTypes: [],
    defectTypes: [],
    sprintId: undefined,
    sprintGroupId: null,
    stageNamePattern: "",
    jobNamePattern: "",
    setIsLoaded: () => {},
    setTimeRange: () => {},
    setScopeFilters: () => { },
    setTeams: () => {},
    setProjects: () => { },
    setIssueTypes: () => { },
    setDefectTypes: () => { },
    setSprintId: () => { },
    setSprintGroupId: () => { },
    getFilterSpec: () => ({}),
    setStageNamePattern: () => { },
    setJobNamePattern: () => { },
}

const FiltersContext = createContext(initialContext)

export const FiltersProvider = ({children}: Props) => {
    const [isLoaded, setIsLoaded] = useState(initialContext.isLoaded)
    const [timeRange, setTimeRange] = useState(initialContext.timeRange)
    const [scopeFilters, setScopeFilters] = useState(initialContext.scopeFilters)
    const [projects, setProjects] = useState(initialContext.projects)
    const [teams, setTeams] = useState(initialContext.teams)
    const [issueTypes, setIssueTypes] = useState(initialContext.issueTypes)
    const [sprintId, setSprintId] = useState(initialContext.sprintId)
    const [sprintGroupId, setSprintGroupId] = useState(initialContext.sprintGroupId)
    const [defectTypes, setDefectTypes] = useState(initialContext.defectTypes)
    const [stageNamePattern, setStageNamePattern] = useState(initialContext.stageNamePattern)
    const [jobNamePattern, setJobNamePattern] = useState(initialContext.jobNamePattern)

    return (
        <FiltersContext.Provider value={{
            isLoaded, setIsLoaded,
            timeRange, setTimeRange,
            teams, setTeams,
            projects, setProjects,
            issueTypes, setIssueTypes,
            defectTypes, setDefectTypes,
            sprintId, setSprintId,
            sprintGroupId, setSprintGroupId,
            stageNamePattern, setStageNamePattern,
            jobNamePattern, setJobNamePattern,
            scopeFilters, setScopeFilters,
            getFilterSpec,
        }}>
            {children}
        </FiltersContext.Provider>
    )

    function getFilterSpec(filters: FilterId[]): FilterSpecDto {
        return filters.reduce((spec, filterId) => filterDefsById[filterId].applyFilter(spec, {
            isLoaded,
            timeRange,
            teams,
            projects,
            issueTypes,
            defectTypes,
            sprintId,
            sprintGroupId,
            stageNamePattern,
            jobNamePattern,
        }), {} as FilterSpecDto)
    }
}

export function useFilters() {
    return useContext(FiltersContext) as IFiltersContext
}

export function useEditableFilters() {
    return useContext(FiltersContext)
}

export function useFiltersScope() {
    return useContext(FiltersContext) as IScopeFiltersContext
}