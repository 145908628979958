import {useQuery} from "react-query";
import {dataSetApi, DataSetResultDto, QueryRequest} from "@services/dataSetApi";
import {useFilters} from "@components/filters/FiltersContext";

type UseDataSetArgs = QueryRequest & {
    //simulate empty result
    isEmpty?: boolean,

    // Whether to wait for global filter context readiness.
    // Specify `false` when filter context is not used by the requested dataset.
    // `true` by default.
    waitForFiltersLoaded?: boolean
}

export const useDataSet = ({isEmpty, waitForFiltersLoaded = true, ...query}: UseDataSetArgs) => {
    const {isLoaded: filtersLoaded} = useFilters()

    const filtersLoadedIfNeeded = !waitForFiltersLoaded || filtersLoaded

    const result = useQuery({
        queryKey: ['dataset_query', query, isEmpty, filtersLoadedIfNeeded],
        queryFn: async ({signal}) => isEmpty || !filtersLoadedIfNeeded
            ? {data: [], dataSetName: 'dataSetName', columns: [],} as DataSetResultDto
            : await dataSetApi.query(query, signal),
        meta: query.queryMeta,
    })

    const isLoading = result.isLoading || !filtersLoadedIfNeeded

    return {
        ...result,
        // override fields in case we need to simulate loading query
        isLoading,
        data: isLoading ? undefined : result.data,
    }
}
