import {DateRangePicker, DateRangePickerValue, MultiSelect, MultiSelectItem, Select, SelectItem} from '@tremor/react';
import React, {useEffect, useState} from "react";
import {FilterId, TimeRangeValueType, TimeRangeValueTypes, useEditableFilters} from "@components/filters/FiltersContext";
import NoChartData from "@components/common/NoChartData";
import {getWordInCase} from "@components/common/dateTimeStringUtils";
import {useFilterValuesQuery} from "@services/queries";
import {useQuery} from "react-query";
import {sprintApi} from "@services/sprintApi";
import {from} from "linq-to-typescript";

export function DashboardFiltersView() {
    const {data, ...queryResult} = useFilterValuesQuery()
    const {data: sprints, ...sprintsQueryResult} = useQuery('sprints', () => sprintApi.getSprints())
    const {data: sprintGroups, ...sprintGroupsQueryResult} = useQuery('sprintGroups', () => sprintApi.getSprintGroups())
    const [customTimeRange, setCustomTimeRange] = useState<DateRangePickerValue | undefined>(undefined)
    const [sprintsInitialized, setSprintsInitialized] = useState(false)
    const [issueTypesInitialized, setIssueTypesInitialized] = useState(false)
    const {
        timeRange,
        teams,
        projects,
        issueTypes, setIssueTypes,
        defectTypes,
        scopeFilters,
        sprintId, setSprintId,
        sprintGroupId, setSprintGroupId,
        isLoaded: filterLoaded, setIsLoaded: setFiltersLoaded,
        ...filters
    } = useEditableFilters()

    useEffect(() => {
        if (data && !filterLoaded) {
            setIssueTypes(data.issueTypes.filter(x => x.isUserStory))
            setIssueTypesInitialized(true)
        }
    }, [data, filterLoaded, setIssueTypes])

    useEffect(() => {
        if (sprints && !filterLoaded) {
            const sprint = from(sprints).firstOrDefault()
            setSprintId(sprint ? sprint.sprintId : undefined)
            setSprintsInitialized(true)
        }
    }, [sprints, filterLoaded, setSprintId])

    useEffect(() => {
        if (issueTypesInitialized && sprintsInitialized)
            setFiltersLoaded(true)
    }, [issueTypesInitialized, sprintsInitialized, setFiltersLoaded]);

    if (!data) {
        return <NoChartData title="Фильтры" {...queryResult} />
    }

    if (!sprints) {
        return <NoChartData title="Фильтры" {...sprintsQueryResult} />
    }

    if (!sprintGroups) {
        return <NoChartData title="Фильтры" {...sprintGroupsQueryResult} />
    }

    const allDefectTypes = data.issueTypes.filter(x => x.isDefect)

    return <div>
        {scopeFilters.includes(FilterId.TimeRange) &&
            <div className="mt-3">
                <label htmlFor="filter-timerange" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Временной период</label>
                <Select
                    id="filter-timerange"
                    className={"mt-1"}
                    value={timeRange.type}
                    onValueChange={type => filters.setTimeRange({type: type as TimeRangeValueType})}
                    defaultValue={TimeRangeValueTypes.All[0].type}
                    enableClear={false}
                >
                    {TimeRangeValueTypes.All.map(x => <SelectItem key={x.type} value={x.type}>{x.name}</SelectItem>)}
                </Select>
                {timeRange.type === 'custom' &&
                    <DateRangePicker
                        id="filter-timerange-custom"
                        className={"mt-2"}
                        placeholder={"Выберите период..."}
                        enableYearNavigation={true}
                        enableSelect={false}
                        value={customTimeRange}
                        onValueChange={v => {
                            setCustomTimeRange(v)
                            filters.setTimeRange({type: 'custom', custom: v})
                        }}
                        weekStartsOn={1}
                    />}
            </div>}

        {scopeFilters.includes(FilterId.Teams) && <div className="mt-3">
            <label htmlFor="filter-teams" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Команда</label>
            <MultiSelect
                id="filter-teams"
                className={"mt-1"}
                value={teams.map(x => x.value)}
                onValueChange={ids => filters.setTeams(data?.teams.filter(x => ids.includes(x.value)))}
                placeholderSearch=""
                placeholder={`${data.teams.length} ${getWordInCase('команда', data.teams.length, 'nominative')}`}
            >
                {data.teams.map(x => <MultiSelectItem key={x.value} value={x.value}>{x.name}</MultiSelectItem>)}
            </MultiSelect>
        </div>}

        {scopeFilters.includes(FilterId.Projects) && <div className="mt-3">
            <label htmlFor="filter-projects" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Проект</label>
            <MultiSelect
                id="filter-projects"
                className={"mt-1"}
                value={projects.map(x => x.value)}
                onValueChange={ids => filters.setProjects(data?.projects.filter(x => ids.includes(x.value)))}
                placeholderSearch=""
                placeholder={`${data.projects.length} ${getWordInCase('проект', data.projects.length, 'nominative')}`}
            >
                {data.projects.map(x => <MultiSelectItem key={x.value} value={x.value}>{x.name}</MultiSelectItem>)}
            </MultiSelect>
        </div>}

        {scopeFilters.includes(FilterId.IssueTypes) && <div className={"mt-3"}>
            <label htmlFor="filter-issue-types" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Тип задачи</label>
            <MultiSelect
                id="filter-issue-types"
                className={"mt-1"}
                value={issueTypes.map(x => x.value)}
                onValueChange={ids => setIssueTypes(data?.issueTypes.filter(x => ids.includes(x.value)))}
                placeholderSearch=""
                placeholder={`${data.issueTypes.length} ${getWordInCase('значение', data.issueTypes.length, 'nominative')}`}
            >
                {data.issueTypes.map(x => <MultiSelectItem key={x.value} value={x.value}>{x.name}</MultiSelectItem>)}
            </MultiSelect>
        </div>}

        {scopeFilters.includes(FilterId.DefectTypes) && <div className={"mt-3"}>
            <label htmlFor="filter-defect-types" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Тип дефекта</label>
            <MultiSelect
                id="filter-defect-types"
                className={"mt-1"}
                value={defectTypes.map(x => x.value)}
                onValueChange={ids => filters.setDefectTypes(data?.issueTypes.filter(x => ids.includes(x.value)))}
                placeholderSearch=""
                placeholder={`${allDefectTypes.length} ${getWordInCase('значение', allDefectTypes.length, 'nominative')}`}
            >
                {allDefectTypes.map(x => <MultiSelectItem key={x.value} value={x.value}>{x.name}</MultiSelectItem>)}
            </MultiSelect>
        </div>}

        {scopeFilters.includes(FilterId.SprintGroup) && <div className="mt-3">
            <label htmlFor="filter-sprint-group" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Проект/команда</label>
            <Select
                id="filter-sprin-group"
                className={"mt-1"}
                placeholder={"Выберите проект/команду..."}
                value={sprintGroupId ?? ''}
                onValueChange={setSprintGroupId}
            >
                {sprintGroups.map(x => <SelectItem key={x.id} value={x.id}>{x.name}</SelectItem>)}
            </Select>
        </div>}

        {scopeFilters.includes(FilterId.Sprint) && <div className="mt-3">
            <label htmlFor="filter-sprint" className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Спринт</label>
            <Select
                id="filter-sprint"
                className={"mt-1"}
                placeholder={"Выберите спринт..."}
                value={sprintId ?? ''}
                onValueChange={setSprintId}
            >
                {sprints.map(x => <SelectItem key={x.sprintId} value={x.sprintId}>{x.sprintName}</SelectItem>)}
            </Select>
        </div>}

    </div>
}