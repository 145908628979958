import IntermediateLoaderCircle from "@components/common/IntermediateLoaderCircle";
import {ExclamationIcon} from "@heroicons/react/outline";
import {Callout, Title} from "@tremor/react";
import {ApiRequestError} from "@services/api";

export type NoChartDataProps<TError> = {
    title: string | null
    isLoading?: boolean
    isError?: boolean
    error?: TError
    isEmpty?: boolean
    message?: string
}

export default function NoChartData<TError>({title, isLoading, isError, error, isEmpty, message}: NoChartDataProps<TError>) {
    let statusContent = <div>Ошибка загрузки данных</div>;

    if (message) {
        statusContent = <div>{message}</div>
    }
    else if (isLoading) {
        statusContent = <IntermediateLoaderCircle />
    }
    else if (isEmpty) {
        statusContent = <div style={{display: "inline-block"}}>Нет данных 😔, попробуйте изменить настройки фильтров 👉</div>
    }
    else if (isError && error instanceof ApiRequestError && error.isForbidden()) {
        statusContent = <div>Ошибка загрузки данных: нет доступа</div>
    }
    else if (!isError) {
        console.error("Unexpected no-data state", arguments)
        statusContent = <ExclamationIcon />
    }

    if (error) {
        console.warn(error)
    }

    return <div>
        {title ? <Title>{title}</Title> : null}
        {statusContent}
    </div>
}