type WordCase =
    'nominative' |  // именительный кто/что
    'accusative'    // винительный кого/что

export type FixedInterval = 'month' | 'week' | 'day' | 'year' | 'quarter'

const wordsInCases: {[word: string]: {[aCase in WordCase]: [string, string, string]}} = ({
    'день': {
        'nominative': ['день', 'дня', 'дней'],
        'accusative': ['день', 'дня', 'дней']
    },
    'неделя': {
        'nominative': ['неделя', 'недели', 'недель'],
        'accusative': ['неделю', 'недели', 'недель']
    },
    'месяц': {
        'nominative': ['месяц', 'месяца', 'месяцев'],
        'accusative': ['месяц', 'месяца', 'месяцев']
    },
    'год': {
        'nominative': ['год', 'года', 'лет'],
        'accusative': ['год', 'года', 'лет']
    },
    'четверть': {
        'nominative': ['четверть', 'четверти', 'четвертей'],
        'accusative': ['четверть', 'четверти', 'четвертей']
    },
    'проект': {
        'nominative': ['проект', 'проекта', 'проектов'],
        'accusative': ['проект', 'проекта', 'проектов']
    },
    'команда': {
        'nominative': ['команда', 'команды', 'команд'],
        'accusative': ['команду', 'команды', 'команд']
    },
    'участник': {
        'nominative': ['участник', 'участника', 'участников'],
        'accusative': ['участника', 'участников', 'участников']
    },
    'значение': {
        'nominative': ['значение', 'значения', 'значений'],
        'accusative': ['значение', 'значения', 'значений']
    },
    'стори поинт': {
        'nominative': ['стори поинт', 'стори поинта', 'стори поинтов'],
        'accusative': ['стори поинт', 'стори поинта', 'стори поинтов']
    },
    'задача': {
        'nominative': ['задача', 'задачи', 'задач'],
        'accusative': ['задачу', 'задачи', 'задач']
    }
})

const intervalRu : {[interval in FixedInterval]: string} = {
    'day': 'день',
    'week': 'неделя',
    'month': 'месяц',
    'year': 'год',
    'quarter': 'четверть'
}

const intervalShortRu : {[interval in FixedInterval]: string} = {
    'day': 'д.',
    'week': 'нед.',
    'month': 'мес.',
    'year': 'г.',
    'quarter': 'четв.'
}

const getQuantityIndex = (quantity: number) => {
    if (!quantity) {
        return 2
    }

    if (quantity < 0) {
        quantity = -quantity
    }

    const str = quantity.toString()
    if (str.includes('.') || str.includes('e') || str.includes('E')) {
        return 1
    }

    if (str.length <= 1 || str[str.length-2] !== '1') {
        const last = str[str.length-1]

        switch (last) {
            case '1':
                return 0
            case '2':
            case '3':
            case '4':
                return 1
        }
    }

    return 2
}

export const translateIntervalNameToShortString = (interval: FixedInterval) => intervalShortRu[interval]

export const translateIntervalNameToString = (interval: FixedInterval, quantity: number, wordCase: WordCase = 'nominative') =>
    getWordInCase(intervalRu[interval], quantity, wordCase)

export const getWordInCase = (word: string, quantity: number, wordCase: WordCase) => wordsInCases[word][wordCase][getQuantityIndex(quantity)]

export const toLocalISODateTimeString = (date: Date): string => {
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds())

    function pad(num: number) {
        return (num < 10 ? '0' : '') + num;
    }
}